import React, {useCallback} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import Container from "@mui/material/Container";

export function Theme(callback, deps) {
    const [alignment, setAlignment] = React.useState('light');

    const getPrefFromLocalStorage = useCallback(() => {
        localStorage.getItem("themePref")
    },[localStorage.getItem("themePref")]);

    React.useEffect(() => {
        let themePref = localStorage.getItem("themePref");
        if(themePref !== null) setAlignment(themePref);

    },[getPrefFromLocalStorage]);




    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        localStorage.setItem("themePref", newAlignment)
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        Theme color
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                        >
                            <ToggleButton value="light">Light Theme</ToggleButton>
                            <ToggleButton value="dark">Dark Theme</ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}