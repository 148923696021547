import React from "react";
import AsyncExample from "../components/Addressearch/AddressSearchTypeAheadSearch";
import BusinessUnitsList from "../components/Addressearch/BusinessUnits";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import {Copyright} from "@mui/icons-material";

export function AddressSearch() {

    const [selectedSearchAddress, setSelectedSearchAddress] = React.useState();
    const handleSelectedAddressChange = (addr) => {
        setSelectedSearchAddress(addr);
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <AsyncExample selectedAddress={handleSelectedAddressChange} />
                        <BusinessUnitsList selectedAddress={selectedSearchAddress} />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
  );
}
