import React from "react";

import {AddressSearch} from 'Pages/AddressSearch';
import {SalesProjects} from 'Pages/SalesProjects';
import {Navigate, Route, Routes} from 'react-router-dom';
import LoginPage from "../Pages/LoginPage";
import Dashboard from "./Dashboard";
import {Theme} from "../Pages/Theme";
import {MapSearch} from "../Pages/MapSearch";
import { useKeycloak } from '@react-keycloak/web'
import {Users} from "../Pages/Users";
import {Stats} from "../Pages/Stats";



function RequireAuth({children, redirectTo}) {
    const { keycloak } = useKeycloak()
    return keycloak.authenticated ? children : <Navigate to={redirectTo}/>;
}


const Router = () => {
    const { initialized } = useKeycloak()

    if (!initialized) {
        return <div>Loading...</div>
    }

    return (
        <Routes>
            <Route path="/stats" element={
                <RequireAuth redirectTo="/">
                    <Dashboard>
                        <Users/>
                    </Dashboard>
                </RequireAuth>
            }/>
            <Route path="/users" element={
                <RequireAuth redirectTo="/">
                    <Dashboard>
                        <Stats/>
                    </Dashboard>
                </RequireAuth>
            }/>
            <Route path="/addressearch" element={
                <RequireAuth redirectTo="/">
                    <Dashboard>
                        <AddressSearch/>
                    </Dashboard>
                </RequireAuth>
            }/>
            <Route path="/salesproject" element={
                <RequireAuth redirectTo="/">
                    <Dashboard>
                        <SalesProjects/>
                    </Dashboard>
                </RequireAuth>
            }/>
            <Route path="/theme" element={
            <RequireAuth redirectTo="/">
                <Dashboard>
                    <Theme />
                </Dashboard>
            </RequireAuth>
            }/>
            <Route path="/mapsearch" element={
                <RequireAuth redirectTo="/">
                    <Dashboard>
                        <MapSearch />
                    </Dashboard>
                </RequireAuth>
            }/>
            <Route path="/" element={<LoginPage />}/>
        </Routes>
    );
};

export default Router;
