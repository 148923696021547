import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import MapIcon from "@mui/icons-material/Map";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Link } from "react-router-dom";



export const mainListItems = (
    <div>
        {[
            {text: "Sales Project", icon: <AccountTreeIcon/>, ref: "/salesproject", divider: false},
            {text: "Address Search", icon: <MapsHomeWorkIcon/>, ref: "/addressearch", divider: false},
            {text: "Map Search", icon: <MapIcon/>, ref: "/mapsearch", divider: false},
            {text: "App Stats", icon: <QueryStatsIcon/>, ref: "/appstats", divider: true},
            {text: "Users", icon: <ManageAccountsIcon/>, ref: "/users", divider: false},
            {text: "App Settings", icon: <SettingsApplicationsIcon/>, ref: "/appsettings", divider: false},
        ].map((linkValue, index) => (
            <ListItem button key={linkValue.text} component={Link} to={linkValue.ref} divider={linkValue.divider}>
                <ListItemIcon>
                    {linkValue.icon}
                </ListItemIcon>
                <ListItemText primary={linkValue.text}/>
            </ListItem>
        ))}
    </div>
);

export const secondaryListItems = (
    <div>
        {[
            {text: "Help", icon: <HelpCenterIcon/>, ref: "/help", divider: false},
            {text: "Theme", icon: <DarkModeIcon/>, ref: "/theme", divider: true},
        ].map((linkValue, index) => (
            <ListItem button key={linkValue.text} component={Link} to={linkValue.ref} divider={linkValue.divider}>
                <ListItemIcon>
                    {linkValue.icon}
                </ListItemIcon>
                <ListItemText primary={linkValue.text}/>
            </ListItem>
        ))}
    </div>
);