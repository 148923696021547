import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import throttle from 'lodash/throttle';
import {Box} from "@mui/material";

const SEARCH_URI_ADDR = "https://ws.geonorge.no/adresser/v1/sok";

export default function Asynchronous(props) {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(open && options.length === 0);


    const fetchResult = React.useMemo(
        () =>
            throttle((request, callback) => {
                console.log("req", request)
                setLoading(true)
                fetch(`${SEARCH_URI_ADDR}?side=0&asciiKompatibel=true&treffPerSide=10&sok=${encodeURIComponent(request.input)}&sokemodus=AND`)
                    .then((resp) => resp.json())
                    .then(callback)
                    .catch(err => console.log(err));
                setLoading(false)
            }, 100),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        let searchString = inputValue.trim().replace(' ', '* ') + '*'
        inputValue.length > 3 && fetchResult({input: searchString}, (results) => {
            if (active) {
                let newOptions = [];

                if (results) {
                    console.log(results)
                    newOptions = [...results.adresser];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchResult]);

    const createDisplayString = (address) => {
        if (address === undefined) return "";
        return `${address.adressetekst}, ${address.postnummer} ${address.poststed}`
    }


    return (
        <Box>
            <Autocomplete
                id="asynchronous-address-search"
                sx={{pb: 3, width: '100%'}}
                open={open}
                onOpen={() => {
                    if(inputValue && inputValue.length > 0) setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}

                isOptionEqualToValue={(option, value) => option.adressekode === value.adressekode}

                // Trick it to use the server result without implying own simplistic filtrationnp
                filterOptions={() => options}

                getOptionLabel={createDisplayString}

                options={options}
                loading={loading}

                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    props.selectedAddress(newValue);
                }}

                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}

                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Search for any address ..."
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20}/>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
}
