import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {blue, green, red} from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import BusinessIcon from '@mui/icons-material/Business';
import MapIcon from '@mui/icons-material/Map';
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useState } from "react";
import { Badge, Box, Grid, Pagination, Stack } from "@mui/material";
import throttle from 'lodash/throttle';

const SEARCH_URI_BU_REG = process.env.REACT_APP_ADMIN_PORTAL_API_ENDPOINT;

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    fontSize: 12,
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

const getAge = birthDate => Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e+10)


const BusinessUnitsList = (props) => {

    const [expandedId, setExpanded] = React.useState(-1);
    const [options, setOptions] = useState([]);
    const [page, setPage] = useState(null);
    const [roleOptions, setRoleOptions] = useState([]);
    const [financeIsRun, setFinanceIsRun] = useState(true)
    const [businessDetails, setBusinessDetails] = useState();
    const [selectedAddress, setSelectedAddress] = useState();
    const [selectedPage, setSelectedPage] = useState(0);
    const [selectedBusinessUnitNo, setSelectedBusinessUnitNo] = useState(0);

    React.useEffect(() => {
        if(props !== undefined) {
            setSelectedAddress(props.selectedAddress);
        }
    },[props]);

    const handlePageChange = (event, value) => {
        setSelectedPage(value-1);
        setExpanded(-1);
    }
    
    const handleExpandClick = (index, businessUnitNo) => {
        setExpanded(expandedId===index ? -1 : index);
        if(businessUnitNo !== selectedBusinessUnitNo ) setSelectedBusinessUnitNo(businessUnitNo)
    };

    const fetchResult = React.useMemo(
        () =>
          throttle((request, callback) => {
            fetch(`${SEARCH_URI_BU_REG}/BusinessUnits?businessStreetAddress=${encodeURIComponent(request.address)}&businessStreetAddressZipCode=${encodeURIComponent(request.zipcode)}&curPage=${request.page}`,
                {headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("authTokens"))?.token}})
            .then((resp) => resp.json())
            .then((jsonBody) => {
                return {
                    page: jsonBody.page,
                    addresses: jsonBody.businessUnits.map((i) => ({
                    organisasjonsnummer: i.businessId,
                    businessUnitForm: i.businessUnitForm,
                    businessSectorCode: i.businessSectorCode,
                    name: i.name,
                    businessAddress: i.businessAddress,
                    postalAddress: i.postalAddress,
                    businessEstablished: i.establisedTime,
                    businessAge: getAge(i.establisedTime),
                    accountingReport: i.accountingReport
                }
            ))}})
            .then(callback)
            .catch(err => console.log(err));
          }, 100),
        [],
      );

      const fetchBusinessUnitRoles = React.useMemo(
        () =>
          throttle((request, callback) => {
            fetch(`${SEARCH_URI_BU_REG}/BusinessUnitRole/${encodeURIComponent(request)}`,
                {headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem("authTokens"))?.token}})
            .then((resp) => resp.json())
            .then(({ rollegrupper }) => {
                return rollegrupper.map((roleGroup, roleGroupIndex) => ({
                    roles: roleGroup.roller.map((y) => ({
                        name: y.person === null ? y.enhet.navn[0] : `${y.person.navn.fornavn} ${y.person.navn.etternavn}`,
                        age: y.enhet === null ? getAge(y.person.fodselsdato) : -1,
                        role: y.roleType.beskrivelse,
                        isPerson: y.enhet === null
                    }))
                }));
            })
            .then(callback)
            .catch(err => console.log(err));
          }, 100),
        [],
      );


    React.useEffect(() => {
        let active = true;

        if(!selectedAddress){
            setOptions(null);
            return;
        } 

        let address = selectedAddress.adressetekst;
        let zipcode = selectedAddress.postnummer;
        let coordinates = selectedAddress.representasjonspunkt;
        let page = selectedPage;

        fetchResult({address, zipcode, page}, (results) => {
            if (active) {
            
              if (results) {
                setPage(results.page);
                setOptions(results.addresses);
                setFinanceIsRun(false);
              }
            }
          });

          return () => {
            active = false;
          };
    },[selectedPage, selectedAddress, fetchResult]);


    React.useEffect(() => {
        let active = true;

        if(selectedBusinessUnitNo === 0) return;

        fetchBusinessUnitRoles(selectedBusinessUnitNo, (results) => {
            if (active) {
              let newOptions = [];
              if (results) {
                  newOptions = [...roleOptions, {"businessUnitNo": selectedBusinessUnitNo, "roleTypes" : results}];
              }
              setRoleOptions(newOptions);
            }
          });
          return () => {
            active = false;
          };

    },[selectedBusinessUnitNo, fetchBusinessUnitRoles])

    const handleOpenExternalLink = (link) => {
        return () => window.open(link);
    }
    
    const createRoleList = (roleList, businessUnitNo) => {
        if(roleList.length === 0) return;
        
        const selectedRoleList =
            roleList.find((item) => item.businessUnitNo === businessUnitNo)

        if (selectedRoleList === undefined) return;

        return <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {selectedRoleList.roleTypes.map((roleType, index) => (
                    roleType.roles.map((role, index) => (
                        <ListItem key={index} >
                            <ListItemAvatar>
                                {role.isPerson
                                    ? <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={<SmallAvatar>{role.age}</SmallAvatar>}>
                                        <Avatar sx={{bgcolor: green[500]}}><PersonIcon/></Avatar>
                                     </Badge>
                                    : <Avatar sx={{bgcolor: blue[500]}}><BusinessIcon/></Avatar>
                                }
                            </ListItemAvatar>
                            <ListItemText 
                                sx={{ px: 0.5, cursor: "pointer", '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.07)"}, borderRadius: '0.5em'}} 
                                primary={role.name} 
                                secondary={role.role} 
                                onClick={handleOpenExternalLink(`https://www.1881.no/?query=${role.name}`)}/>
                        </ListItem>
            ))
        ))}</List>
    };
    
    const createFinanceInfoBox = (cardDetails) => {

        if(cardDetails.length === 0 || cardDetails.accountingReport === null) return;

        const driftsResultat = cardDetails.accountingReport.incomeStatement;

        return <Box
                    sx={{
                    pt: 0.1,
                    fontSize: 14,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(6, 1fr)',
                    gap: 1,
                    gridTemplateRows: 'auto',
                    gridTemplateAreas: 
                    `"main1 main1 main1 . sidebar1 sidebar1"
                    "main2 main2 main2 . sidebar2 sidebar2"
                    "main3 main3 main3 . sidebar3 sidebar3"`,
            }}>

                <Box sx={{ gridArea: 'sidebar1' }}>
                    {driftsResultat.opRev}
                </Box>
                
                <Box sx={{ gridArea: 'main1', cursor: 'pointer' , '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.07)"}, borderRadius: '0.5em'}} >
                Driftsinntekter
                </Box>

                <Box sx={{ gridArea: 'sidebar2' }}>
                    {driftsResultat.opEx}
                </Box>
                
                <Box sx={{ gridArea: 'main2', cursor: 'pointer', '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.07)"}, borderRadius: '0.5em'}}>
                Driftskostnad
                </Box>

                <Box sx={{  gridArea: 'sidebar3' }}>
                    {driftsResultat.netResult}

                </Box>
                
                <Box sx={{ gridArea: 'main3', cursor: 'pointer', '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.07)"}, borderRadius: '0.5em'}}>
                Driftsresultat
                </Box>

            </Box>
    }

    
    const CardList = (params) => {

        if (params.length === 0 || !params.options) return <br/>;

        return <><Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
            {params.options.map((cardDetail, index ) => (
                <Grid item xs={2} sm={4} md={4} key={cardDetail.organisasjonsnummer}>
                    <Card key={cardDetail.organisasjonsnummer} sx={{ maxWidth: 450 }}>
                        <CardHeader
                            avatar={
                                <Avatar sx={{ bgcolor: red[500], fontSize: 16 }} aria-label="businessType" >
                                    {cardDetail.businessUnitForm}
                                </Avatar>
                            }
                            action={
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            title={cardDetail.name}
                            subheader={cardDetail.organisasjonsnummer}
                            
                        />
                        {/*<CardMedia
                        component="img"
                        height="194"
                        image="/static/images/cards/paella.jpg"
                        alt="Paella dish"
                    />*/}
                        <CardContent>
                            <Typography variant="body2" color="text.secondary">
                                {cardDetail.businessSectorCode}
                            </Typography>
                            <Box
                                sx={{
                                pt: 1,
                                fontSize: 14,
                                display: 'grid',
                                gridTemplateColumns: 'repeat(6, 1fr)',
                                gap: 1,
                                gridTemplateRows: 'auto',
                                gridTemplateAreas: 
                                `"sidebar main main main main ."
                                "sidebar2 main2 main2 main2 main2 ."`,
                                }}>

                                <Box sx={{ gridArea: 'sidebar' }}><BusinessIcon /></Box>
                                
                                <Box sx={{ gridArea: 'main', cursor: 'pointer', p: 0.5, '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.07)"}, borderRadius: '0.5em'}} 
                                onClick={handleOpenExternalLink(`https://www.1881.no/?query=${cardDetail.organisasjonsnummer}`)}>
                                    {cardDetail.businessAddress}
                                </Box>

                                {cardDetail.postalAddress &&
                                    <><Box sx={{ gridArea: 'sidebar2' }}>
                                        <MailOutlineIcon />
                                    </Box>
                                    <Box sx={{ gridArea: 'main2', cursor: 'pointer', p: 0.5, '&:hover': { backgroundColor: "rgba(0, 0, 0, 0.07)"}, borderRadius: '0.5em'}}>
                                        {cardDetail.postalAddress}
                                    </Box></>
                                }
                            </Box>
                            {createFinanceInfoBox(cardDetail)}
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                                <ShareIcon />
                            </IconButton>
                            <IconButton>
                                <MapIcon aria-label="view in map"/>
                            </IconButton>
                            <ExpandMore
                                expand={expandedId === index}
                                //expand={index}
                                onClick={() => handleExpandClick(index, cardDetail.organisasjonsnummer)}
                                //aria-expanded={expanded}
                                aria-expanded={expandedId === index}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                        </CardActions>
                        <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
                            <CardContent>
                                {createRoleList(params.companyRoleDetails, cardDetail.organisasjonsnummer)}
                            </CardContent>
                        </Collapse>
                    </Card>
                </Grid>
            ))}

        </Grid>
            {page !== null &&
                <Box display="flex" justifyContent="center">
                    <Stack spacing={2} >
                        <Pagination count={page?.totalPages ?? 0} page={page?.number+1 ?? 0} onChange={handlePageChange} />
                    </Stack>
                </Box>
            }
        </>
    }
                
    return (
        <Box>
            <CardList
                options={options}
                companyDetails={businessDetails}
                companyRoleDetails={roleOptions}
            />
        </Box>

    );
}

export default BusinessUnitsList;