import {BrowserRouter} from 'react-router-dom';
import keycloak from "./components/Auth/Keycloak"
import {ReactKeycloakProvider} from "@react-keycloak/web";

import React from 'react';


import './App.css';
import Router from "./components/Router";

function App() {
    const onTokens = (tokens) => {
        localStorage.setItem("authTokens", JSON.stringify(tokens));
    }
    
  return (
    <>
        <ReactKeycloakProvider authClient={keycloak} onTokens={onTokens}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
        </ReactKeycloakProvider>
    </>
  );
}

export default App;
