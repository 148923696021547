import React, { Component } from 'react';
import Container from "@mui/material/Container";

export class Users extends Component {
    static displayName = Users.name;
    

    //constructor(props) {
    //    super(props);
    //}
    
    render() {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

            <h1>Sales Projects</h1>

                <p>This is a simple example of a React component.</p>
                
            </Container>

        );
    }
}