import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {Component} from "react";
import Container from "@mui/material/Container";


export class Stats extends Component {
    static displayName = Stats.name;


    //constructor(props) {
    //    super(props);
    //}

    render() {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                <Grid container spacing={3}>
                    {/* Chart */}
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <p>hei</p>
                        </Paper>
                    </Grid>
                    {/* Recent Deposits */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 240,
                            }}
                        >
                            <p>hei</p>
                        </Paper>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <p>hei</p>
                        </Paper>
                    </Grid>
                </Grid>

            </Container>

        );
    }
}
